import '../css/Entry.css';
import React, { useEffect, useState } from 'react';

const Entry = (props) => {

    const [body, setBody] = useState('');

    useEffect(() => {
    // Fetch the file from the public directory
    fetch('/blog-bodies/'+props.body)
      .then((response) => response.text())
      .then((text) => setBody(text));
    }, []);

    return (
        <div className="Entry">
            <div className="title">{props.index}. {props.title}</div>
            <div className="name">{props.name}</div>
            <div className="date">{props.date}</div>
            <div className="body">{body}</div>
        </div>
    )
}

export default Entry;
// LIBRARIES
import '../css/Home.css';
import React, { useLayoutEffect, useState } from 'react';

// ASSETS
import wolf from '../wolf-face.png';

// COMPONENTS
import Entry from '../components/Entry'

const Home = () => {
    return(
        <div className="Home">
            <h1>HOME</h1>
            <div>Hello, this is the Home page for our CISC322 Project (Fall 2024)</div>
            <Entry  
                    index={"1"}
                    title={"Placeholder Title"} 
                    name={"Huy Truong"} date={"1969/04/20"} 
                    body={"one.txt"}
                    
            />
        </div>
    )
}

export default Home;
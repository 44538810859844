import '../css/Header.css';
import React, { useLayoutEffect, useState } from 'react';

const Header = () => {
    return (
        <div className="Header">
            <a href="/home">HOME</a>
        </div>
    );
}

export default Header;